import Head from 'next/head';
import { NextSeo } from 'next-seo';

import { getVehicleSchemaLD } from 'components/SearchPage/helpers/seo';

import type { Ad } from 'api/types/searchPageApiTypes';

type SearchHeadProps = {
  currentSection: string;
  h1: string;
  title: string;
  description: string;
  url: string;
  isMotor: boolean;
  ads?: Ad[];
};

const SearchHead = (props: SearchHeadProps) => {
  const { currentSection, title, description, url, ads, h1, isMotor } = props;

  const isVehicleSchemaSection =
    isMotor ||
    currentSection === 'vintagecars' ||
    currentSection === 'tractors';

  const hasAds = ads && ads.length > 0;

  const images = ads?.[0]?.gallery?.coverImage.large
    ? [
        {
          url: ads?.[0]?.gallery?.coverImage.large,
          width: 600,
          height: 450,
        },
      ]
    : undefined;

  return (
    <>
      {isVehicleSchemaSection && hasAds && (
        <Head>
          <script type="application/ld+json">
            {getVehicleSchemaLD(ads ?? [], currentSection, h1)}
          </script>
        </Head>
      )}
      <NextSeo
        title={title}
        description={description}
        canonical={url}
        nofollow={!hasAds}
        noindex={!hasAds}
        openGraph={{
          url,
          type: 'product',
          title,
          description,
          images,
        }}
      />
    </>
  );
};

export { SearchHead };
