import type { ListItem } from 'components/Toolkit/Inputs/CustomSelect';
import type { Options } from 'components/SearchPage/features/makeModel/MakeModel.typed';
import {
  SearchableDropdown,
  useSearchableDropdown,
} from 'components/ToolkitV2/SearchableDropdown/SearchableDropdown';

type MakeFilterProps = {
  value: string;
  options: Options[];
  initialFocus?: boolean;
  addMake: (item: ListItem) => void;
};

const MakeFilter = (props: MakeFilterProps) => {
  const { value, options, initialFocus = true, addMake } = props;
  const { search, onClear, highlight, ...searchBoxProps } =
    useSearchableDropdown({
      isSelected: false,
      selectedValue: value,
      overrides: { threshold: 0, focus: initialFocus },
    });

  const filteredOptions = options
    // Ensures there are no duplicate matches between groups
    .filter((option) => option.title === 'All Makes')
    .map((option) => ({
      // Don't show group title while searching
      title: null,
      // This will filter the items within the group
      items: option.items.filter((item) =>
        item.displayName.toLowerCase().includes(search.toLowerCase()),
      ),
    }));

  const makes = !!search ? filteredOptions : options;

  const onSelectMake = (item: ListItem) => {
    onClear();
    addMake(item);
  };

  const isSelected = (item: ListItem) => item.value === value;
  const isDisabled = (item: ListItem) => !isSelected(item) && item.isDisabled;

  return (
    <SearchableDropdown>
      <SearchableDropdown.Searchbox
        value={search}
        placeholder="Search any make"
        dataTestId="make-dropdown-input"
        onClear={onClear}
        {...searchBoxProps}
      />
      <SearchableDropdown.List
        dataTestId="make-dropdown-list"
        noResults={makes[0]?.items?.length === 0}
      >
        {makes.map((make, index) => (
          <SearchableDropdown.ListGroup
            key={`${make.title}-${index}`}
            title={make.title}
          >
            {make.items.map((item, index) => (
              <SearchableDropdown.ListItem
                key={`${item.value}-${index}`}
                onClick={() => !isSelected(item) && onSelectMake(item)}
                dataTestId={`make-dropdown-listitem-${index}`}
                disabled={isDisabled(item)}
              >
                {isDisabled(item)
                  ? item.displayName
                  : highlight(item.displayName)}
              </SearchableDropdown.ListItem>
            ))}
          </SearchableDropdown.ListGroup>
        ))}
      </SearchableDropdown.List>
    </SearchableDropdown>
  );
};

export { MakeFilter };
