import type { ListItem } from 'components/Toolkit/Inputs/CustomSelect';
import type { Options } from './MakeModel.typed';

const mapListItemToOption = (items?: Array<ListItem>): Options['items'] => {
  return (
    items
      ?.filter(({ value }) => value !== '')
      .map(({ displayName, value }) => ({
        displayName,
        value,
      })) || []
  );
};

const mapMakeOptions = (
  all?: Array<ListItem>,
  popular?: Array<ListItem>,
): Options[] => {
  const allOptions: Options = {
    title: 'All Makes',
    items: mapListItemToOption(all),
  };

  const popularOptions: Options = {
    title: 'Popular',
    items: mapListItemToOption(popular),
  };

  const options: Options[] = [
    { title: null, items: [{ value: '', displayName: 'All Makes' }] },
    popularOptions,
    allOptions,
  ];

  return options;
};

const mapDisabledOptions = (
  options: Array<Options>,
  selectedMakes?: Array<string>,
): Array<Options> => {
  return options.map((option) => {
    return {
      ...option,
      items: option.items.map((item) => ({
        value: item.value ?? '',
        displayName: item.displayName ?? '',
        isDisabled:
          selectedMakes && item.value
            ? selectedMakes?.includes(item.value)
            : false,
      })),
    };
  });
};

const mapModelOptions = (
  all?: Array<ListItem>,
  popular?: Array<ListItem>,
): Options[] => {
  const allOptions: Options = {
    title: 'All Models',
    items: mapListItemToOption(all),
  };

  const popularItems = mapListItemToOption(popular);

  const popularOptions: Options = {
    title: popularItems.length !== 0 ? 'Popular' : null,
    items: mapListItemToOption(popular),
  };

  const options: Options[] = [
    { title: null, items: [{ value: '', displayName: 'All Models' }] },
    popularOptions,
    allOptions,
  ];

  return options;
};

const mapMakeModelQuery = (makeModelValue: string, isEditable?: boolean) => {
  const [make, model] = makeModelValue.split(';');
  const modelValue = model ? model.split('model:') : [];

  const mappedMakeAndModels = {
    make,
    model: modelValue[1]?.split(','),
    isEditable,
  };

  return mappedMakeAndModels;
};

/*
 *  We may map make model values on the server. In this case, we pass type-compliant
 *  null values rather than undefined
 */
const mapServerMakeModels = (
  make?: string,
  model?: string,
  makeQuery?: string | Array<string> | null,
): Array<{ make: string; model: Array<string> | null }> | undefined => {
  if (make) {
    if (model) {
      return [{ make, model: [model] }];
    }
    return [{ make, model: null }];
  }
  if (Array.isArray(makeQuery)) {
    return makeQuery.map((item) => {
      const mappedQuery = mapMakeModelQuery(item, false);
      return { ...mappedQuery, model: mappedQuery.model ?? null };
    });
  }
  if (typeof makeQuery === 'string') {
    const mappedQuery = mapMakeModelQuery(makeQuery, false);
    return mappedQuery
      ? [{ ...mappedQuery, model: mappedQuery.model ?? null }]
      : undefined;
  }
  return undefined;
};

export {
  mapMakeOptions,
  mapDisabledOptions,
  mapModelOptions,
  mapMakeModelQuery,
  mapServerMakeModels,
};
