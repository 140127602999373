import styled, { css } from 'styled-components';

import {
  Dropdown,
  SButtonDropdown,
} from 'components/Toolkit/Dropdown/Dropdown';
import {
  InputButton,
  SButtonInput,
} from 'components/Toolkit/InputButton/InputButton';

/**
 * This is needed to override the styles of the `InputButton` within the `Dropdown` component.
 *
 * This seems non-ideal. Overriding the styles of a nested component by applying the following
 * to a parent component creates a coupling that is not obvious. It would be better to have
 * a single design for the `InputButton` disabled state across the application.
 */
export const SButtonInputDisabled = css`
  ${SButtonInput},
  ${SButtonDropdown} {
    background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
    color: ${({ theme }) => theme.colors.GREY_DARK};
    border-color: ${({ theme }) => theme.colors.GREY_LIGHT};
  }
`;

type DropdownProps = {
  isDisabled?: boolean;
};
export const SDropdown = styled(Dropdown)<DropdownProps>`
  ${({ isDisabled }) => isDisabled && SButtonInputDisabled};
`;

export const SInputButton = styled(InputButton)<DropdownProps>`
  ${({ theme, isDisabled }) =>
    isDisabled &&
    `
    background-color: ${theme.colors.GREY_LIGHTER};
    color: ${theme.colors.GREY_DARK};
    border-color: ${theme.colors.GREY_LIGHT};
  `};
`;

export const ModelOption = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing.M16};
  gap: ${({ theme }) => theme.spacing.S8};
  background-color: ${({ theme }) => theme.colors.WHITE};
`;
