import { Checkbox } from 'components/Toolkit/Inputs/Checkbox';
import {
  SearchableDropdown,
  useSearchableDropdown,
} from 'components/ToolkitV2/SearchableDropdown/SearchableDropdown';
import { useMakeModelContext } from '../MakeModelContext';
import * as Styled from 'components/SearchPage/features/makeModel/MakeModel.styled';

type ModelFilterProps = {
  selected: string[];
  placeholder: string;
  initialFocus?: boolean;
  addModel: (item: string) => void;
};

const ModelFilter = (props: ModelFilterProps) => {
  const { selected, placeholder, initialFocus = true, addModel } = props;
  const { modelOptions } = useMakeModelContext();
  const { search, highlight, ...searchBoxProps } = useSearchableDropdown({
    isSelected: false,
    selectedValue: null,
    overrides: { threshold: 0, focus: initialFocus },
  });

  const isChecked = (item: string) => {
    return selected.includes(item);
  };

  const filteredOptions = modelOptions
    // Ensures there are no duplicate matches between groups
    ?.filter((option) => option.title === 'All Models')
    .map((option) => ({
      // Don't show group title while searching
      title: null,
      // This will filter the items within the group
      items: option.items.filter((item) =>
        item.displayName.toLowerCase().includes(search.toLowerCase()),
      ),
    }));

  const models = search ? filteredOptions : modelOptions;

  return (
    <SearchableDropdown>
      <SearchableDropdown.Searchbox
        placeholder={placeholder}
        value={search}
        dataTestId="model-dropdown-input"
        {...searchBoxProps}
      />
      <SearchableDropdown.List
        noResults={models && models[0]?.items.length === 0}
      >
        {models?.map((option) => (
          <SearchableDropdown.ListGroup key={option.title} title={option.title}>
            {option.items.map((item) => (
              <SearchableDropdown.ListItem
                key={item.value}
                onClick={() => addModel(item.value)}
                dataTestId="searchable-dropdown-listitem"
              >
                <Styled.ModelOption>
                  {highlight(item.displayName)}
                  <span>
                    <Checkbox
                      willUseSubText={false}
                      checked={isChecked(item.value)}
                      onFocus={() => addModel(item.value)}
                    />
                  </span>
                </Styled.ModelOption>
              </SearchableDropdown.ListItem>
            ))}
          </SearchableDropdown.ListGroup>
        ))}
      </SearchableDropdown.List>
    </SearchableDropdown>
  );
};

export { ModelFilter };
