import { Filter, Range, MakeModel } from 'domains/Filter';
import { ISearchPageFilter, ISortField } from 'api/types/searchPageApiTypes';
import {
  formatPathParameters,
  getSectionParams,
  mapFilterAndRangeValues,
  getFilterPathParameterData,
} from 'helpers/seo/searchParams';
import { ResponseMakeModelFilterValue, ResponseModelFilter } from 'types';
import { useURLStateManagement } from 'components/SearchPage/hooks/useURLStateManagement/useURLStateManagement';
import { useMemo, useState } from 'react';
import { useLocationContext } from 'features/location/Location.context';
import {
  getUrlWithMakeModelEditable,
  getFiltersUrl,
} from 'features/filters/Filters.helper';
import { useNextRouter } from 'hooks/UseNextRouter';
import {
  mapFilters,
  mapRanges,
  mapMakeModels,
} from 'features/filters/Filters.mapper';
import { useFiltersFormatting } from 'features/filters/FiltersFormatting.hook';
import { FilterDispatch, FilterState } from 'features/filters/Filters.typed';
import { findCountyByValue } from 'features/location/helpers';
import { formatMakeAndModelQueriesForFilters } from 'components/SearchPage/helpers/formatting';

export const useFilters = (
  initialFiltersData: ISearchPageFilter[],
  initialSortFields: ISortField[],
  filtersMakeModelData: ResponseModelFilter,
  userId?: string | string[],
) => {
  const { updateUrlByHref, router } = useNextRouter();
  // TODO: create reducer
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filtersData, setFiltersData] =
    useState<ISearchPageFilter[]>(initialFiltersData);
  const [sortFields, setSortFields] = useState<ISortField[]>(initialSortFields);

  const { getFilterCopy } = useFiltersFormatting();
  const { locationDisplay } = useLocationContext();
  const {
    currentSectionQueryValue,
    keywordQueryValue,
    sortQueryValue,
    radiusQueryValue,
    countyTownQueryValue,
    carFilterQueryValue,
    areaQueryValue,
    latitudeQueryValue,
    longitudeQueryValue,
    resetURL,
  } = useURLStateManagement();

  const { query } = router;
  const { section, make: makeQuery, radius, countyTown } = query;

  const { makePathParameter, modelPathParameter, SEOFilterValue } =
    formatPathParameters(section);

  const displayRadius = countyTown && radius ? ` (+${radius}km)` : '';
  const displayModel = modelPathParameter ? ` ${modelPathParameter}` : '';

  const locationCopy = locationDisplay
    ? `${locationDisplay}${displayRadius}`
    : undefined;

  const makeModelCopy = makePathParameter
    ? `${makePathParameter}${displayModel}`
    : formatMakeAndModelQueriesForFilters(makeQuery);

  const staticParams = {
    ...(userId && { userId }),
    words: keywordQueryValue,
    sort: sortQueryValue,
    start: '',
  };

  const {
    countyList,
    coordinates: initialCoordinates,
    levelOneReset,
  } = useLocationContext();

  const validRadius = countyTownQueryValue ? radiusQueryValue : undefined;
  const countyPathParam = findCountyByValue(countyList, carFilterQueryValue);
  const county = countyPathParam?.value || areaQueryValue;
  const latitude = latitudeQueryValue || initialCoordinates.latitude;
  const longitude = longitudeQueryValue || initialCoordinates.longitude;

  const hiddenQueryValues =
    latitude && longitude ? `&latitude=${latitude}&longitude=${longitude}` : '';

  const { sectionQueryParams, makeModelParams } = getSectionParams(
    filtersData,
    query,
  );

  const newQuery = {
    ...query,
    ...sectionQueryParams,
  };

  delete newQuery.section;

  const { filterGroup, rangeGroup } = mapFilterAndRangeValues(
    {
      ...query,
      ...sectionQueryParams,
    },
    filtersData,
    userId,
  );

  const makeModelEditable = query.makeModelEditable;

  const filters: Filter[] = useMemo(
    () => mapFilters(filtersData, filterGroup),
    // TODO Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...JSON.stringify(filterGroup)],
  );

  const ranges: Range[] = useMemo(
    () => mapRanges(filtersData, rangeGroup),
    // TODO Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...JSON.stringify(rangeGroup)],
  );

  const newFiltersMakeModel: MakeModel[] = useMemo(
    () => mapMakeModels(makeModelParams, query),
    // TODO Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...JSON.stringify(makeModelParams), makeModelEditable],
  );

  const findFilterByName = (
    filters: ISearchPageFilter[],
    filterName: string,
  ) => {
    return filters.find((filter) => filter.name === filterName);
  };

  const makeModelDataFilters = findFilterByName(
    filtersData,
    'makeModel',
  )?.values;

  const newFiltersMakeModelData = {
    make: makeModelDataFilters?.length
      ? (makeModelDataFilters[0] as unknown as ResponseMakeModelFilterValue)
      : ([] as unknown as ResponseMakeModelFilterValue),
    model: filtersMakeModelData,
  };

  const isMakeModelSection =
    filtersData.filter((item) => item.name === 'makeModel').length > 0;

  const isMakeSelected = makePathParameter || query.make;

  const calculateFiltersRangeCount = () => {
    let count = 0;
    for (const filter of filters) {
      if (filter.values.length > 1) {
        count += 1;
      } else if (filter.values.length === 1 && filter.values[0] !== '') {
        count += 1;
      }
    }
    for (const range of ranges) {
      if (
        typeof range.to !== 'undefined' ||
        typeof range.from !== 'undefined'
      ) {
        count += 1;
      }
    }
    if (county) {
      count += 1;
    }
    if (isMakeSelected) {
      count += 1;
    }
    return count;
  };

  const getFilterValues = (
    filter: ISearchPageFilter,
    ignoreDefaultValue: boolean = false,
  ): string | undefined => {
    const filterValues = filters
      ?.find((item) => item.name === filter.name)
      ?.values.join('');

    if (filterValues) {
      return filterValues;
    }

    if (ignoreDefaultValue) {
      return undefined;
    }

    return filter.values?.[0]?.value ?? '';
  };

  const getFilterData = (name: string) =>
    filtersData.find((filterItem) => filterItem.name === name);

  const getCountOfFiltersApplied = (search: string, sort: string) => {
    let count = 0;
    const makeModelCount = newFiltersMakeModel.filter(
      (makeModelGroup) =>
        makeModelGroup.makeValue.length > 0 &&
        makeModelGroup.makeValue !== 'All Makes' &&
        makeModelGroup.makeValue !== '',
    ).length;
    const locationCount = county ? 1 : 0;

    count += locationCount;
    count += calculateFiltersRangeCount();
    count += makeModelCount;
    count += search ? 1 : 0;
    count += sort ? 1 : 0;
    return count;
  };

  const updateFiltersUrl = (
    updateFilters: Filter[] = filters,
    updateRange: Range[] = ranges,
    updateMakeModel: MakeModel[] = newFiltersMakeModel,
  ) => {
    const url = getFiltersUrl(
      currentSectionQueryValue,
      staticParams,
      updateFilters,
      updateMakeModel,
      updateRange,
      countyTownQueryValue,
      validRadius,
      county,
    );
    if (makeModelEditable) {
      const updatedUrlAs = getUrlWithMakeModelEditable(
        url,
        makeModelEditable as string,
      );
      updateUrlByHref(`${updatedUrlAs}${hiddenQueryValues}`, url);
    } else {
      updateUrlByHref(`${url}${hiddenQueryValues}`, url);
    }
  };

  const updateFilter = (updateFilter: Filter) => {
    const { name, values } = updateFilter;
    const index = filters.findIndex((item) => item.name === name);
    if (index !== -1) {
      filters[index].values = values;
    } else {
      filters.push({ name, values });
    }
    updateFiltersUrl(filters);
  };

  const deleteFilterMultiValue = (updateFilter: Filter) => {
    const index = filters.findIndex(
      (filter) => filter.name === updateFilter.name,
    );
    if (index !== -1) {
      filters[index] = {
        ...filters[index],
        values: filters[index].values.filter(
          (value) => value !== updateFilter.values[0],
        ),
      };
    } else {
      // @ts-ignore
      const newFilter = { name: updateFilter.name, ...updateFilter };
      filters.push(newFilter);
    }
    updateFiltersUrl(filters);
  };
  const updateFilterMultiValue = (updateFilter: Filter) => {
    const index = filters.findIndex(
      (filter) => filter.name === updateFilter.name,
    );
    if (index !== -1) {
      filters[index] = {
        ...filters[index],
        values: filters[index].values.concat(updateFilter.values),
      };
    } else {
      // @ts-ignore
      const newFilter = { name: updateFilter.name, ...updateFilter };
      filters.push(newFilter);
    }

    updateFiltersUrl(filters);
  };
  const updateRange = (updateRange: Range, updateFilter?: Filter) => {
    const index = ranges.findIndex((range) => range.name === updateRange.name);
    if (index !== -1) {
      ranges[index] = { ...ranges[index], ...updateRange };
    } else {
      const newRange = { ...updateRange };
      ranges.push(newRange);
    }
    updateFiltersUrl(
      updateFilter ? [...filters, updateFilter] : filters,
      ranges,
    );
  };
  const updateMakeModel = (
    newMakeModel: MakeModel[],
    makeModelEditableId?: string,
  ) => {
    const url = getFiltersUrl(
      currentSectionQueryValue,
      staticParams,
      filters,
      newMakeModel,
      ranges,
      countyTownQueryValue,
      validRadius,
      county,
    );

    if (makeModelEditableId) {
      const updatedUrlAs = getUrlWithMakeModelEditable(
        url,
        makeModelEditable as string,
      );
      updateUrlByHref(`${updatedUrlAs}${hiddenQueryValues}`, url);
    } else {
      updateUrlByHref(`${url}${hiddenQueryValues}`, url);
    }
  };
  const reset = () => {
    resetURL();
    levelOneReset();
  };

  const displayFilterAndRangeText = (filter: ISearchPageFilter) => {
    const { filterPathParameter, formattedFilterName } =
      getFilterPathParameterData([filter], SEOFilterValue);

    const filterQuery =
      filterPathParameter && formattedFilterName === filter.name
        ? filterPathParameter
        : query;

    return getFilterCopy(filter, filterQuery);
  };

  const displayText = (filter: ISearchPageFilter) => {
    switch (filter.name) {
      case 'location':
        return locationCopy;
      case 'makeModel':
        return makeModelCopy;
      case 'make':
        return makePathParameter;
      default:
        return displayFilterAndRangeText(filter);
    }
  };

  const state: FilterState = {
    filters,
    ranges,
    makeModel: newFiltersMakeModel,
    filtersData,
    sortFields,
    makeModelData: newFiltersMakeModelData,
    isMakeModelSection,
    isFilterModalOpen,
    staticParams,
    appliedFiltersRangeCount: calculateFiltersRangeCount(),
  };

  const dispatch: FilterDispatch = {
    setIsFilterModalOpen,
    getCountOfFiltersApplied,
    getFilterData,
    getFilterValues,
    updateFilter,
    updateFiltersUrl,
    updateFilterMultiValue,
    deleteFilterMultiValue,
    updateRange,
    updateMakeModel,
    reset,
    displayText,
    setFiltersData,
    setSortFields,
  };

  return {
    state,
    dispatch,
  };
};
