import { Ad } from 'api/types/searchPageApiTypes';

export const getVehicleSchemaLD = (
  ads: Ad[],
  section: string,
  title: string,
) => {
  const formatPriceWithoutComma = (price: string) => price.replace(',', '');
  const formatPriceToNumber = (price: string) =>
    Number(formatPriceWithoutComma(price));

  const sortAds =
    ads &&
    ads.length > 0 &&
    ads
      .filter((item) => item.priceInfo?.price)
      .sort(
        (a, b) =>
          formatPriceToNumber(a.priceInfo?.price) -
          formatPriceToNumber(b.priceInfo?.price),
      );

  const getAdsMinPrice = () =>
    sortAds &&
    sortAds[0] &&
    formatPriceWithoutComma(sortAds[0].priceInfo?.price);

  const getAdsMaxPrice = () =>
    sortAds &&
    sortAds.length > 0 &&
    sortAds[sortAds.length - 1] &&
    formatPriceWithoutComma(sortAds[sortAds.length - 1].priceInfo?.price);

  const getCondition = () => {
    return section === 'new-cars' ? 'New' : 'UsedCondition';
  };

  return JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'Vehicle',
    name: title,
    model: '',
    itemCondition: getCondition(),
    brand: {
      '@type': 'Thing',
      name: '',
    },
    offers: {
      '@type': 'AggregateOffer',
      lowPrice: getAdsMinPrice(),
      highPrice: getAdsMaxPrice(),
      priceCurrency: 'EUR',
      availability: 'In Stock',
    },
  });
};
