import { DisplayContainer } from 'components/Support/DisplayContainer/DisplayContainer';

import { BottomSheet } from 'components/Toolkit/BottomSheet/BottomSheet';
import { MakeModelProps } from 'components/SearchPage/features/makeModel/MakeModel.typed';
import { useMakeModelContext } from 'components/SearchPage/features/makeModel/MakeModelContext';
import { Dropdown } from 'components/Toolkit/Dropdown/Dropdown';

import { AddButton } from 'components/SearchPage/features/makeModel/components/AddButton';
import { MakeFilter } from 'components/SearchPage/features/makeModel/components/MakeFilter';
import { InputButton } from 'components/Toolkit/InputButton/InputButton';

import { Spacer } from 'helpers/genericStyles';
import { useMakeModel } from 'components/SearchPage/features/makeModel/MakeModel.hook';
import { Selected } from 'components/SearchPage/features/makeModel/components/Selected/Selected';
import { ModelFilter } from './components/ModelFilter';
import { Button } from 'components/Toolkit/Button';
import { StickyFooter } from 'components/ToolkitV2/StickyFooter/StickyFooter';

import * as Styled from './MakeModel.styled';

const MakeModel = (props: MakeModelProps) => {
  const {
    mWebConfig,
    webConfig,
    selectMake: onSelectMake,
    selectModel: onSelectModel,
    maximumAmountOfMakeModelGroupsToBeDisplayed,
    onAddSelection,
    makeOptions,
    heightVariant,
  } = props;

  const { isMakeModelBottomSheetOpen, isExpanded, setIsExpanded, makeModels } =
    useMakeModelContext();

  const {
    addMake,
    clearMake,
    openBottomSheet,
    closeBottomSheet,
    makeValue,
    selectedModelOptions,
    addModel,
    clearModels,
    modelPlaceholder,
    editableMakeModelGroupAvailable,
    isAddAnotherDisabled,
    isModelFilterDisabled,
    mappedMakeOptions,
    isModelDropdownExpanded,
    isModelSheetExpanded,
    setIsModelDropdownExpanded,
    setIsModelSheetExpanded,
  } = useMakeModel({
    onSelectMake,
    onSelectModel,
    maximumAmountOfMakeModelGroupsToBeDisplayed,
    makeOptions,
  });

  return (
    <div data-testid="make-model-dropdowns">
      {makeModels && makeModels.length > 0 && (
        <Selected
          makeModels={makeModels}
          clearSelection={clearMake}
          heightVariant={heightVariant}
        />
      )}
      {editableMakeModelGroupAvailable && (
        <>
          <DisplayContainer config={mWebConfig}>
            <InputButton
              isActive={isMakeModelBottomSheetOpen}
              value={makeValue}
              onClick={openBottomSheet}
              placeholder="Make"
              heightVariant={heightVariant}
              dataTestId="mobile-make-input-button"
            />
            <BottomSheet
              headerText={makeValue}
              askToClose={closeBottomSheet}
              isOpen={isMakeModelBottomSheetOpen}
              onApply={closeBottomSheet}
              height="75vh"
            >
              {isMakeModelBottomSheetOpen && (
                <MakeFilter
                  value={makeValue}
                  options={mappedMakeOptions}
                  addMake={addMake}
                  // Setting focus on mobile iOS causes zoom which leads to bad UX
                  initialFocus={false}
                />
              )}
            </BottomSheet>
            <Spacer marginBottom="M16" />
            <Styled.SInputButton
              placeholder={modelPlaceholder}
              isActive={isModelSheetExpanded}
              value={modelPlaceholder}
              heightVariant={heightVariant}
              isDisabled={isModelFilterDisabled}
              onClick={() => setIsModelSheetExpanded(true)}
              dataTestId="mobile-model-input-button"
            />
            <BottomSheet
              headerText={modelPlaceholder}
              askToClose={() => setIsModelSheetExpanded(false)}
              isOpen={isModelSheetExpanded}
              height="75vh"
              onApply={() => setIsModelSheetExpanded(false)}
              buttonA={
                <Button ofType="SECONDARY" onClick={clearModels}>
                  Clear
                </Button>
              }
              buttonB={
                <Button onClick={() => setIsModelSheetExpanded(false)}>
                  Done
                </Button>
              }
            >
              {isModelSheetExpanded && (
                <ModelFilter
                  addModel={addModel}
                  selected={selectedModelOptions}
                  placeholder={`Search ${makeValue} models`}
                  // Setting focus on mobile iOS causes zoom which leads to bad UX
                  initialFocus={false}
                />
              )}
            </BottomSheet>
          </DisplayContainer>
          <DisplayContainer config={webConfig}>
            <Dropdown
              data-testid="desktop-make-dropdown"
              expanded={isExpanded}
              setExpanded={setIsExpanded}
              displayedValue={makeValue}
              placeholder="Make"
              heightVariant={heightVariant}
              resetScrollPosition={isExpanded}
            >
              {isExpanded && (
                <MakeFilter
                  value={makeValue}
                  options={mappedMakeOptions}
                  addMake={addMake}
                />
              )}
            </Dropdown>
            <Spacer marginBottom="M16" />
            <Styled.SDropdown
              data-testid="desktop-model-dropdown"
              expanded={isModelDropdownExpanded}
              setExpanded={setIsModelDropdownExpanded}
              displayedValue={modelPlaceholder}
              placeholder={`Search ${makeValue} models`}
              isDisabled={isModelFilterDisabled}
              heightVariant={heightVariant}
            >
              {isModelDropdownExpanded && (
                <ModelFilter
                  addModel={addModel}
                  selected={selectedModelOptions}
                  placeholder={`Search ${makeValue} models`}
                />
              )}
              <StickyFooter>
                <Styled.ButtonContainer>
                  <Button ofType="SECONDARY" onClick={clearModels}>
                    Clear
                  </Button>
                  <Button onClick={() => setIsModelDropdownExpanded(false)}>
                    Done
                  </Button>
                </Styled.ButtonContainer>
              </StickyFooter>
            </Styled.SDropdown>
          </DisplayContainer>
          <Spacer marginBottom="M16" />
          <Spacer marginBottom="M16" />
          <AddButton
            disabled={isAddAnotherDisabled}
            onClick={onAddSelection}
            size={heightVariant === 'LARGE' ? 'LARGE' : 'SMALL'}
          />
        </>
      )}
    </div>
  );
};

export { MakeModel };
