import { useMakeModelContext } from 'components/SearchPage/features/makeModel/MakeModelContext';

import type { MakeModel } from 'components/SearchPage/features/makeModel/MakeModel.typed';

type UseMakeModelWriteURLArgs = {
  filterName?: string;
  searchQueryGroup?: string;
  updateMakeModels: (args: {
    makeModels?: Array<{
      make?: string;
      model?: Array<string>;
    }>;
    filterName?: string;
    searchQueryGroup?: string;
    makeModelEditable?: string;
  }) => void;
  maximumAmountOfMakeModelGroupsToBeDisplayed: number;
};

const useMakeModelWriteURL = (args: UseMakeModelWriteURLArgs) => {
  const {
    filterName,
    updateMakeModels,
    searchQueryGroup,
    maximumAmountOfMakeModelGroupsToBeDisplayed,
  } = args;

  const { makeModels } = useMakeModelContext();

  const onSelectMake = (makeOption: string) => {
    if (!makeOption) {
      selectDefaultMakeOption();
      return;
    }
    if (makeModels) {
      const containsMatchingMakeModel = makeModels.some(
        ({ make }) => make === makeOption,
      );

      if (containsMatchingMakeModel) {
        removeMake(makeModels, makeOption);
      } else {
        checkForEditableMakeAndUpdate(makeModels, makeOption);
      }
    } else {
      updateMakeModels({
        makeModels: [
          {
            make: makeOption,
          },
        ],
        filterName,
        makeModelEditable: 'make_model_0',
        searchQueryGroup,
      });
    }
  };

  const selectDefaultMakeOption = () => {
    // Remove the reference to the make associated with the editable group of make models, if any, from the URL
    if (makeModels) {
      const editableMake = makeModels?.find(
        ({ isEditable }) => isEditable,
      )?.make;
      if (editableMake) {
        removeMake(makeModels, editableMake);
      }
    }
  };

  const removeMake = (
    mappedMakeModels: Array<{
      make: string;
      isEditable?: boolean;
    }>,
    makeOption: string,
  ) => {
    const updatedMakeModels = mappedMakeModels.filter(
      ({ make }) => make !== makeOption,
    );

    const makeModelEditableIndex =
      findMakeModelEditableIndex(updatedMakeModels);

    updateMakeModels({
      makeModels: updatedMakeModels.length > 0 ? updatedMakeModels : undefined,
      filterName,
      searchQueryGroup,
      makeModelEditable:
        makeModelEditableIndex >= 0
          ? `make_model_${makeModelEditableIndex}`
          : undefined,
    });
  };

  const checkForEditableMakeAndUpdate = (
    mappedMakeModels: Array<MakeModel>,
    makeOption: string,
  ) => {
    const containsEditableMake = mappedMakeModels.some(
      ({ isEditable }) => isEditable,
    );
    const updatedMakeModels = containsEditableMake
      ? mappedMakeModels.map((makeModel) => {
          if (makeModel.isEditable) {
            return {
              make: makeOption,
              isEditable: true,
            };
          }
          return makeModel;
        })
      : [...mappedMakeModels, { make: makeOption, isEditable: true }];

    const makeModelEditableIndex =
      findMakeModelEditableIndex(updatedMakeModels);

    updateMakeModels({
      makeModels: updatedMakeModels.length > 0 ? updatedMakeModels : undefined,
      filterName,
      searchQueryGroup,
      makeModelEditable:
        makeModelEditableIndex >= 0
          ? `make_model_${makeModelEditableIndex}`
          : undefined,
    });
  };

  const findMakeModelEditableIndex = (makeModels: Array<MakeModel>) =>
    makeModels.findIndex(({ isEditable }) => isEditable);

  const onSelectModel = (makeOption: string, modelOption: string) => {
    if (makeModels) {
      const mappedMakeModels = makeModels.map((item) => {
        if (item.make === makeOption && modelOption === '') {
          return {
            ...item,
            model: undefined,
          };
        }
        if (item.make === makeOption) {
          const mappedModels = item.model?.includes(modelOption)
            ? item.model.filter((entry) => entry !== modelOption)
            : [...(item.model ?? []), modelOption];
          return {
            ...item,
            model: mappedModels.length > 0 ? mappedModels : undefined,
          };
        }
        return item;
      });
      updateMakeModels({
        makeModels: mappedMakeModels,
        filterName,
        makeModelEditable: `make_model_${mappedMakeModels.length - 1}`,
        searchQueryGroup,
      });
    }
  };

  const onAddSelection = () => {
    const makeModelEditable =
      makeModels &&
      makeModels?.length < maximumAmountOfMakeModelGroupsToBeDisplayed
        ? `make_model_${makeModels?.length}`
        : undefined;

    updateMakeModels({
      makeModels,
      filterName,
      searchQueryGroup,
      makeModelEditable,
    });
  };

  return {
    onSelectMake,
    onSelectModel,
    onAddSelection,
  };
};

export { useMakeModelWriteURL };
