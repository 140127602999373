import { useState } from 'react';

import { useMakeModelContext } from 'components/SearchPage/features/makeModel/MakeModelContext';

import { mapDisabledOptions } from 'components/SearchPage/features/makeModel/MakeModel.map';

import type { Options } from 'components/SearchPage/features/makeModel/MakeModel.typed';
import type { ListItem } from 'components/Toolkit/Inputs/CustomSelect';

type UseMakeModelArgs = {
  onSelectMake: (value: string) => void;
  onSelectModel: (make: string, model: string) => void;
  maximumAmountOfMakeModelGroupsToBeDisplayed: number;
  makeOptions?: Array<Options>;
};

const useMakeModel = (args: UseMakeModelArgs) => {
  const {
    onSelectMake,
    onSelectModel,
    maximumAmountOfMakeModelGroupsToBeDisplayed,
    makeOptions,
  } = args;

  const {
    makeModels,
    setIsMakeModelBottomSheetOpen,
    modelOptions,
    closeDisplay,
  } = useMakeModelContext();

  const [isModelDropdownExpanded, setIsModelDropdownExpanded] = useState(false);
  const [isModelSheetExpanded, setIsModelSheetExpanded] = useState(false);

  const addMake = (make: ListItem) => {
    onSelectMake(make.value);
    closeDisplay();
  };

  const selectModel = (makeValue: string) => (option: string) => {
    onSelectModel(makeValue, option);
  };

  const addModel = (option: string) => selectModel(makeValue)(option);

  const clearModels = () => selectModel(makeValue)('');

  const openBottomSheet = () => setIsMakeModelBottomSheetOpen(true);
  const closeBottomSheet = () => setIsMakeModelBottomSheetOpen(false);

  const makeValue =
    makeModels?.find(({ isEditable }) => isEditable)?.make || 'All Makes';

  const selectedModels = makeModels?.filter(
    (item) => item.make === makeValue,
  )?.[0]?.model;

  const multipleModels =
    selectedModels && selectedModels.length > 1
      ? ` (+${selectedModels.length - 1})`
      : '';

  const modelPlaceholder =
    selectedModels && selectedModels.length > 0 && selectedModels[0]
      ? `${selectedModels[0]}${multipleModels}`
      : 'All Models';

  const editableMakeModelGroupAvailable = !(
    makeModels?.length === maximumAmountOfMakeModelGroupsToBeDisplayed &&
    !makeModels.some(({ isEditable }) => isEditable)
  );

  const selectedModelOptions =
    selectedModels && selectedModels.length > 0 ? selectedModels : [''];
  const isModelFilterDisabled = makeValue === 'All Makes' || !modelOptions;
  const isAddAnotherDisabled = makeValue === 'All Makes';

  const disabledMakes = makeModels?.map(({ make }) => make);

  const mappedMakeOptions = makeOptions
    ? mapDisabledOptions(makeOptions, disabledMakes)
    : [];

  return {
    addMake,
    clearMake: onSelectMake,
    addModel,
    clearModels,
    openBottomSheet,
    closeBottomSheet,
    makeValue,
    selectedModelOptions,
    modelPlaceholder,
    editableMakeModelGroupAvailable,
    isModelFilterDisabled,
    isAddAnotherDisabled,
    mappedMakeOptions,
    isModelDropdownExpanded,
    isModelSheetExpanded,
    setIsModelDropdownExpanded,
    setIsModelSheetExpanded,
  };
};

export { useMakeModel };
